/*
  For performance reason Achieve.com is direct hosting fonts vs. using through ascend/google api
  for improved performance and caching.
*/

/* bold */
@font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/next-assets/fonts/Ultramarine-Bold.woff2') format('woff2'),
    url('/next-assets/fonts/Ultramarine-Bold.woff') format('woff');
}

/* bold italic */
@font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('/next-assets/fonts/Ultramarine-BoldItalic.woff2') format('woff2'),
    url('/next-assets/fonts/Ultramarine-BoldItalic.woff') format('woff');
}

/* italic */
@font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/next-assets/fonts/Ultramarine-Italic.woff2') format('woff2'),
    url('/next-assets/fonts/Ultramarine-Italic.woff') format('woff');
}

/* medium */
@font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/next-assets/fonts/Ultramarine-Medium.woff2') format('woff2'),
    url('/next-assets/fonts/Ultramarine-Medium.woff') format('woff');
}

/* medium italic */
@font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('/next-assets/fonts/Ultramarine-MediumItalic.woff2') format('woff2'),
    url('/next-assets/fonts/Ultramarine-MediumItalic.woff') format('woff');
}

/* regular */
@font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/next-assets/fonts/Ultramarine-Regular.woff2') format('woff2'),
    url('/next-assets/fonts/Ultramarine-Regular.woff') format('woff');
}
