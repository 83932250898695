@use 'styles/variables' as var;
// overwrite transitions for the home-hero carousel to avoid bugs on safari.
// can't use inline CSS with vanilla js because -webkit and -moz don't quite work on safari with this approach

/* stylelint-disable property-no-vendor-prefix */
.main-home-hero-section ul,
.main-home-hero-section li {
  -webkit-transition: all 1.2s ease !important;
  -moz-transition: all 1.2s ease !important;
  -o-transition: all 1.2s ease !important;
  transition: all 1.2s ease !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  color: var.$color-content-primary;
}

footer {
  /* TODO: Update Footer component to support custom styling passing a className to apply those styles. */
  a[data-testid='achieve-logo'] {
    width: 66px !important;
    height: 54px !important;
    overflow: hidden;

    img {
      width: 215px !important;
      min-width: inherit !important;
      max-width: inherit !important;
    }
  }
}
/* stylelint-enable property-no-vendor-prefix */

#tcb-container {
  height: 340px;
}

@media screen and (min-width: var.$breakpoint-sm) {
  #tcb-container {
    height: 228px;
  }
}

@media screen and (min-width: var.$breakpoint-md) {
  #tcb-container {
    height: 160px;
  }
}

@media screen and (min-width: var.$breakpoint-lg) {
  #tcb-container {
    height: 130px;
  }
}
